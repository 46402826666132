import React, { useState, useEffect, useRef } from 'react'
import { Form, Row, Col, Button, Container, FloatingLabel, Modal } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { BASE_URL_FRONTEND } from '../../config/Url-config';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { FaRegPaperPlane, FaDownload, FaRegListAlt, FaSlidersH, FaAlignJustify } from 'react-icons/fa';
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import jsPDF from "jspdf";
import "jspdf-autotable";
import UserCrsVersionsTable from './UserCrsVersionsTable';
import Spinner from 'react-bootstrap/Spinner';
import Paper from '@mui/material/Paper';
import { Alert, Snackbar, Typography } from '@mui/material';
import * as apiUtility from '../../config/APIUtils';
import SuccessPopUp from '../Utility/SuccessPopUp';
import ErrorPopUp from '../Utility/ErrorPopUp';
import { PieChart } from '@mui/x-charts/PieChart';
import { Card, CardContent } from '@mui/joy';
import VerifiedIcon from '@mui/icons-material/Verified';


function UserCourses(props) {
    var paramId = useParams().id;
    var propsId = props.userId;
    var params = propsId;
    if (!propsId) {
        params = paramId;
    }
    var homeTypes = JSON.parse(sessionStorage.getItem("home_types"));

    const [mappingData, setMappingData] = useState([{ '': '' }]);
    const [homeId, sethomeId] = useState(0);
    const [roleId, setroleId] = useState(0);
    const [userId, setuserId] = useState(0);
    const [homeList, sethomelist] = useState([{ '': '' }]);
    const [roleList, setroleList] = useState([{ '': '' }]);
    const [completeCourses, setCompleteCourses] = useState([]);
    const [waitMsg, setWaitMsg] = useState('Loading..');

    const [show, setshow] = useState(false);
    const [alertshow, setalertShow] = useState(false);
    const [successAlertshow, setsuccessAlertshow] = useState(false);

    const [format, setformat] = useState(0);
    const [empEmailId, setempEmailId] = useState('');
    const [userList, setUserList] = useState([]);
    const [mapHomeList, setmapHomeList] = useState([]);
    const [mapRoleList, setmapRoleList] = useState([]);

    const [auditCheck, setAuditCheck] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setsuccessMsg] = useState(false);
    const [showErrorMsg, setShowErrorMsg] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const [homeDropdownValue, sethomeDropdownValue] = useState(0);
    const [roleDropdownValue, setroleDropdownValue] = useState(0);
    const [crsTypeDropdownValue, setcrsTypeDropdownValue] = useState(0);
    const [pieData, setPieData] = useState([
        { id: 0, value: 0, label: 'Outstanding' },
        // { id: 1, value: 0, label: 'Approved' },
        { id: 1, value: 0, label: 'Validated' },
        { id: 2, value: 0, label: 'Expired' },
        // { id: 4, value: 0, label: 'Denied' }
      ]);

    const BASE_URL_EMAIL_TO_EMPLOYER = "user/sendEmailEmp";
    const BASE_URL_GET_All_HOMELIST = "orgnization/getAllHomes";
    const BASE_URL_GET_COURSELIST = "course/fetchCourseDetails";
    const BASE_URL_GET_USER_HOME_ROLE = "user/fetchUHRdetails/";
    const BASE_URL_GET_HOME_ROLE_CRS_LIST = "orgnization/getHomeInfo/";
    const BASE_URL_USER = "user/getUser/";
    const BASE_URL_GET_ALLCOURSELIST = "course/getAllCourses/";
    //const BASE_URL_GET_ALLCOURSE_VERSIONS = "course/getCourseVersion/";
    const BASE_URL_GET_ALLCOURSE_VERSIONS_USER_BASED = "course/getAllCourseVersion_forUser";

    var mapOfHomeType = {};
    homeTypes.forEach(element => {
        mapOfHomeType[element.id] = element.value;
    });

    const tableRef = useRef();
    
    useEffect(() => {
        if (sessionStorage.getItem("userType") != 'admin' && sessionStorage.getItem("userId") != paramId) {
            return window.location.href = BASE_URL_FRONTEND;

        }
        fetchData();
        setuserId(params);
        const getAllCourseData = BASE_URL_GET_ALLCOURSELIST + params;
        setShowSpinner(true);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = getAllCourseData;
        apiUtility.fetchAPIDataGET(apiRequest).then(response => {
            setCompleteCourses(response.data);
            calculateDataForPie(response.data);
            setShowSpinner(false);
        }).catch((error) => console.error(error));;

        const getUserHomeRoleData = BASE_URL_GET_USER_HOME_ROLE + params;
        apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = getUserHomeRoleData;
        apiUtility.fetchAPIDataGET(apiRequest).then(response => {
            //console.log("Response 2: ", response);
            if (response.data !== "No User-Role-Home Mapping Found!") {
                setMappingData(response.data);
                getHomeList(response.data);
            } else {
                getHomeList("");
                console.log(response.data);
            }
            setShowSpinner(false);
        }).catch((error) => { setShowSpinner(false); console.error(error) });;
    }, [params, props.count]);

    async function fetchData() {
        setShowSpinner(true);
        const getUserData = BASE_URL_USER + params
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = getUserData;
        let response = await apiUtility.fetchAsyncAPIDataGET(apiRequest);
        //console.log("Response 3: ", response);
        // let response = await axios.get(
        //     getUserData,{headers}
        // );
        setUserList(response.data.data);
        setShowSpinner(false);
    }

    async function calculateDataForPie(courseDataArr) {
        let expired =0;
        let validated =0;
        //let approved =0;
        let total =0;
        //let denied =0;
        for (const crsData of courseDataArr) {
            total++;
            if(crsData.status === "Expired")
                expired++;
            else if(crsData.status === "Validated")
                validated++;
            // else if(crsData.status === "Denied")
            //     denied++;
            // else if(crsData.status === "Submitted" || crsData.status === "Approved")
            //     approved++;
        }
        total = total - (expired+validated);
        const tempData = [
        { id: 0, value: total, label: 'Outstanding', color: '#0d6efd' },
        //{ id: 1, value: approved, label: 'Approved', color: '#ffc008' },
        { id: 1, value: validated, label: 'Validated', color: '#1a8754' },
        { id: 2, value: expired, label: 'Expired', color: '#cf1f1f' },
       // { id: 4, value: denied, label: 'Denied', color: '#e46d6d' }
      ]
        setPieData(tempData);
    }


    const getHomeList = (resp) => {
        setShowSpinner(true);
        try {
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = BASE_URL_GET_All_HOMELIST;
            apiUtility.fetchAPIDataGET(apiRequest).then(response => {
                //console.log("Response 4: ", response);
                var allHomesList = response.data;
                var list = [];
                if (resp !== "") {
                    var onlyMappedHome = resp;
                    const userMappedHomes = allHomesList.map((c) => {
                        const inner = onlyMappedHome.find(
                            (s) => {
                                if (c.home_id === s.home_id) {
                                    list.push(c);
                                }
                            });
                        return (inner);
                    });
                }
                setmapHomeList(list);
                sethomelist(response.data);
                setShowSpinner(false);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const filterRoleDropdown = e => {
        setShowSpinner(true);
        const { name, value } = e.target
        sethomeId(value);
        setshow(false);
        sethomeDropdownValue(value);
        setroleDropdownValue(0);
        if (value !== 0) {
            setroleList([{ '': '' }]);
            const getUserData = BASE_URL_GET_HOME_ROLE_CRS_LIST + value
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = getUserData;
            apiUtility.fetchAPIDataGET(apiRequest).then(function (response) {
                //console.log("Response 5: ", response);
                setroleList(response.data.result);
                var list = [];
                response.data.result.map((c) => {
                    const inner = mappingData.find(
                        (s) => {
                            // //console.log("Check roles Home => ",c.home_id, "   ", s.home_id);
                            if (c.home_id === s.home_id) {
                                ////console.log("Check roles Role => ",c.role_id);
                                s.role_arr.find(
                                    (v) => {
                                        ////console.log("Role inner loop => ",v ," ", v.role_id == c.role_id, " ", v.emp_status == "Active")
                                        if (v.role_id == c.role_id && v.emp_status == "Active") {
                                            list.push(c)
                                        }
                                    })
                            }
                        });
                    return (inner);
                });
                //console.log("Home Id : ",value," Role list: ", roleList);
                setmapRoleList(list);
                setShowSpinner(false);
                if (response.data.result.length === 0) {
                    setWaitMsg("There is no role mapped with selected Home.")
                    setalertShow(true);
                    setShowSpinner(false);
                }
            })
                .catch(function (error) {
                    setShowSpinner(false);
                    //console.log(error);
                });;
        }
    }

    const setRoleValue = e => {
        const { name, value } = e.target
        setroleId(value);
        setshow(false);
        setroleDropdownValue(value);
    }

    const setCourseTypeValue = e => {
        const { name, value } = e.target
        setshow(false);
        setcrsTypeDropdownValue(value);
    }

    const resetDetails = (crsId) => {
        setShowSpinner(true);
        sethomeDropdownValue(0);
        setroleDropdownValue(0);
        setshow(false);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = BASE_URL_GET_ALLCOURSELIST + params;
        apiUtility.fetchAPIDataGET(apiRequest).then(response => {
            let newProducts = [...response.data];
            let pendingList = (props.activeCartList["pending"] != undefined && props.activeCartList["pending"].length > 0) ? props.activeCartList["pending"] : []
            newProducts = newProducts.map((d) => {
                let courseVersionsAdded = false;
                pendingList.forEach(element => {
                    if (element.courseId === d.crsId) {
                        courseVersionsAdded = true;
                    }
                });
            if (courseVersionsAdded || (crsId != undefined && d.crsId === crsId)) {
                return {
                ...d,
                sessionApproved: true
                };
            }
            return d;
            });
            setCompleteCourses([...newProducts]);
            calculateDataForPie(response.data);
            setShowSpinner(false);
            
        });
    }

    const fetchCourseDetails = () => {
        try {
            setShowSpinner(true);
            //const body = JSON.stringify({ userId: userId, orgId: "", roleId: roleId, homeId: homeId });
            ////console.log("Body to send => ", body);
            var resJson = [];
            setshow(false);
            setCompleteCourses([]);
            setWaitMsg('');
            //console.log("Role : ", roleId, mapRoleList);
            (mapRoleList.some(item => item.role_id == roleId)) ? setsuccessAlertshow(true) : setsuccessAlertshow(false);
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = BASE_URL_GET_COURSELIST;
            apiRequest.data = { userId: userId, orgId: "", roleId: roleId, homeId: homeId, courseType: crsTypeDropdownValue };
            apiUtility.sendAPIDataPOST(apiRequest).then(res => {
                //console.log("Response 6: ",res);
                resJson = res.data;
                if (resJson !== undefined) {
                    if (resJson.courseError) {
                        setWaitMsg("Cannot fetch courses. Try searching from different course industry.")
                        setalertShow(true);
                        setShowSpinner(false);
                    } else {
                        var list = [];
                        var courseIds = [];
                        const inner = resJson.completedCourses.find(
                            (s) => {
                                if (!courseIds.includes(s.crsId)) {
                                    courseIds.push(s.crsId);
                                    list.push(s)
                                }

                            });
                        setCompleteCourses(list);
                        calculateDataForPie(list)
                        setShowSpinner(false);
                        if (resJson.completedCourses.length === 0) {
                            setWaitMsg("Not able to fetch courses.\nNiether user has completed any course nor any course is registered with selected role. \n\nPlease contact your LCPT support team.")
                            setalertShow(true);
                        }
                    }
                } else {
                    setShowSpinner(false);
                    return alert("Selected role don't have courses specified yet. Please contact admin and try again");
                }
            })
                .catch(err => {
                    console.log(err);
                    setShowSpinner(false);
                })
        } catch (error) {
            console.log(error);
            setShowSpinner(false);
        }
    }

    const options = {
        // pageStartIndex: 0,
        sizePerPage: 10,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: false,
        // onPageChange: function (page, sizePerPage) {
        //     alert("Event : Page has been changed")
        //   }
    };

    const handleUpdateCallback = (courseObj) => {
        if(homeDropdownValue != 0 || roleDropdownValue != 0){
            fetchCourseDetails();
        }else{
            resetDetails(courseObj);
        }
    }



    const expandRow = {
        renderer: row => (
            <div>
                <UserCrsVersionsTable data={row} userId={params} handleUpdateCallback={handleUpdateCallback} activeCartList={props.activeCartList} handleCourseCartCallback={props.handleCourseCartCallback}/>
            </div>
        ),
        // expandColumnRenderer: ({ expanded, rowKey, expandable }) => ({
        //     if (rowKey > 2) return 'foo';
        //     return 'bar';
        // }
        //   ),
        text: "View",
        showExpandColumn: true,
        onlyOneExpanding: true,
        expandByColumnOnly: true,
        
    };

    const courseCodeFormatter = (data, row) => {
        let courseVersionsAdded = false;
        let pendingList = (props.activeCartList["pending"] != undefined && props.activeCartList["pending"].length > 0) ? props.activeCartList["pending"] : []
        pendingList.forEach(element => {
            if (element.courseId === row.crsId) {
                courseVersionsAdded = true;
            }
        });
        let verifiedHtml = "";
        if(row.sessionApproved){
            verifiedHtml = <VerifiedIcon style={{fontSize:'1.3pc', marginTop:'2px'}} color='success'/>;
        }else{
            verifiedHtml = <VerifiedIcon style={{fontSize:'1.3pc', marginTop:'2px'}} color='primary'/>
        }
        //console.log("Here ", courseVersionsAdded, " -> ",row.crsId);
        if (row.directRegistration !== undefined && row.directRegistration) {
            return <Typography variant='body' style={{ width:'10rem', display:'flex' }}>
                        {verifiedHtml}&nbsp;{row.crsId}
                </Typography>;
        }else{
            return <Typography variant='body' style={{ width:'10rem' }}>
                       {row.crsId}
                </Typography>;
        }
    };

    const industryFormatter = (data, row) => {
        return mapOfHomeType[row.courseType];
    };

    const crsStatusFormatter = (data, row) => {
        return (row.status !== "Approved"?row.status:"--");
    };
    
    const expiryDateFormatter = (data, row) => {
        return (row.status !== "Approved"?row.valid:"");
    };
    


    const columns = [
        {
            dataField: "crsId",
            text: "Course Code",
            sort: true,
            filter: textFilter(),
            formatter: courseCodeFormatter,
            style: {
                fontWeight: 'bold',
                height: '5%'
            }

        },
        {
            dataField: "title",
            text: "Title",
            sort: true,
            filter: textFilter(),
            title: (cell, row, rowIndex, colIndex) => `${cell}`
        },
        {
            dataField: "description",
            text: "Description",
        },
        {
            dataField: "courseType",
            text: "Applicable Industry",
            sort: true,
            formatter: industryFormatter
        },
        {
            dataField: "validity_string",
            text: "Valid For ",
        },
        {
            dataField: "status",
            text: "Status",
            formatter: crsStatusFormatter
        },
        {
            dataField: "valid",
            text: "Expiry Date",
            formatter: expiryDateFormatter
            //formatter: expiryFormatter
        }
        // {
        //     dataField: "",
        //     text: "View",
        //     formatter: buttonFormatter
        // }
    ];

    const updateEmpEmailId = e => {
        const { name, value } = e.target;
        setempEmailId(value);
        //setauditalertshow(false);
        setShowSuccess(false);
    }

    const sendDataThroughEmail = () => {
        if (format == 0) {
            //excel
            exportAuditExcel(true);
        } else {
            //pdf
            exportAuditPDF(true);
        }
    }

    const downloadData = e => {
        //console.log(format);
        setShowSpinner(true);
        if (format == 0) {
            //excel
            exportAuditExcel(false);
        } else {
            //pdf
            exportAuditPDF(false);
        }
        setsuccessMsg('File downloaded successfully.');
        setShowSuccess(true);
        setTimeout(() => closeAlert, 6000);
    }
    const closeAlert = () => {
        //setauditalertshow(false);
        //setShowSuccess(false);
        setalertShow(false);
        setsuccessAlertshow(false);
    }

    //pdf
    var exportAuditPDF = async (isEmail) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "portrait"; // portrait or landscape
        const compress = true;
        const fontSize = 8;
        const lineHeight = 1;
        const autoSize = false;
        const printHeaders = true;
        const doc = new jsPDF(orientation, unit, size, compress, fontSize, lineHeight, autoSize, printHeaders);
        //const doc = new jsPDF();
        var rowsCount = 6;
        doc.setFontSize(15);
        const title = "Ontario Long Term Care Orientation Co-operative Webportal\nUser Audit Report";

        let userTable = [];//[{ A:'Username',B:'User ID',C:'Email ID',D:'Contact',E:'Date Of Birth',F:'Address',G:'Date'}];
        //var userHeader = [{'name':'Name','userId':'UserId','dob':'Date of Birth','date':'Date'}];
        //var userHeader = [{'first':'Name','sec':userList.firstName+" "+userList.lastName}];
        //var userBody = [{'name':userList.firstName+" "+userList.lastName,'userId':userList.user_id,'dob':userList.dob,'date':new Date().toLocaleString()}];
        //var userBody = [{'first':'UserId','sec':userList.user_id},{'first':'Date Of Birth','sec':userList.dob},{'first':'Date','sec':new Date().toLocaleString()}]
        var userBody = [{}];
        var userHeader = [{}];
        if (roleDropdownValue != 0 && homeDropdownValue != 0) {
            userHeader = [{ 'first': 'Name : ' + userList.firstName + " " + userList.lastName, 'sec': ' ' }];
            var courseSelected = "All";
            var homeName = "";
            var roleName = "";
            for (let element of homeTypes) {
                if (element.id === crsTypeDropdownValue) {
                    courseSelected = element.value;
                }
            };
            for (let element of homeList) {
                if (element.home_id === String(homeDropdownValue)) {
                    homeName = element.name;
                }
            };
            for (let element of roleList) {
                if (element.role_id === String(roleDropdownValue)) {
                    roleName = element.role_name;
                }
            };
            userBody = [{ 'first': 'UserId : ' + userList.user_id, 'sec': 'Home : ' + homeName },
            { 'first': 'Date Of Birth : ' + userList.dob, 'sec': 'Role : ' + roleName },
            { 'first': 'Date : ' + new Date().toLocaleString(), 'sec': 'Course Industry : ' + courseSelected }]
        } else {
            userHeader = [{ 'first': 'Name : ' + userList.firstName + " " + userList.lastName }];
            userBody = [{ 'first': 'UserId : ' + userList.user_id }, { 'first': 'Date Of Birth : ' + userList.dob }, { 'first': 'Date : ' + new Date().toLocaleString() }]
        }
        //var userHeader = [{'first':'Name : '+userList.firstName+" "+userList.lastName,'sec':' '}];
        //var userBody = [{'first':'UserId : '+userList.user_id},{'first':'Date Of Birth : '+userList.dob},{'first':'Date : '+new Date().toLocaleString()}]
        userTable.push([
            userList.userName,
            userList.user_id,
            userList.email,
            userList.number,
            userList.dob,
            (userList.address !== undefined) ? '' : userList.address + ", " + (userList.city !== undefined) ? '' : userList.city +
                "," + (userList.state !== undefined) ? '' : userList.state + " , " + (userList.postalCode !== undefined) ? '' : userList.postalCode,
            new Date().toLocaleString()
        ]);
        let headers = [['Course Code', 'Title', 'Issued By', 'Course Status', 'Expiry Date'
        //'Version ID',  'Training Duration', 'Is Valid',
            ]];
        var tableData = [];

        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = BASE_URL_GET_ALLCOURSE_VERSIONS_USER_BASED;
        apiRequest.data = {
            "courses": completeCourses,
            "userId": userList.user_id
        };
        await apiUtility.sendAPIDataPOST(apiRequest).then(response => {
            for (let item of response.data) {
                rowsCount += 1;
                    var courseVersion = [
                        item.crsId,
                        //item.crsVersionId,
                        item.title,
                        //item.trainDuration,
                        //item.valid,
                        item.orgIssued,
                        item.equivalentCrsStatus,
                        item.validity
                        ];
                    tableData.push(courseVersion);
            }
        }).catch(error => {
            setErrorMsg("Error while processing.");
            setShowErrorMsg(true);
        });

        const lineColor = '#cccccc';
        doc.setDrawColor(lineColor);
        const pageWidth = doc.internal.pageSize.getWidth() - 20;
        doc.rect(40, 20, pageWidth - 60, 55);
        doc.rect(40, 20, pageWidth - 60, 150);
        doc.autoTable({
            head: userHeader,
            body: userBody,
            styles: { font: 'helvetica', cellWidth: 'wrap' },
            //columnStyles: {0: {halign: 'left', fontStyle: 'bold'}},
            startY: 80,
            theme: 'plain'
        });

        const headerBackgroundColor = '#cccccc'; // Set the desired background color
        doc.setFillColor(headerBackgroundColor);
        let content = {
            startY: 190,
            styles: { font: 'helvetica', cellWidth: 'auto', minCellWidth: 2 },
            columnStyles: { 0: { halign: 'center', fontStyle: 'bold' } },//, fillColor: [0, 255, 0]}}, // Cells in first column centered and green
            headStyles: {
                fillColor: [15, 111, 197], halign: 'center'
            },
            margin: { top: 30 },
            head: headers,
            body: tableData,
            theme: 'grid'

        };
        doc.setProperties({
            title: title,
            subject: 'This is the subject',
            author: 'LCPT',
            keywords: 'generated, javascript, web 2.0, ajax',
            creator: 'LCPT',
            bodyStyles: {
                color: '#cccccc'
            }
        });
        doc.setFontSize(15);
        doc.text(title, 300, 40, {
            align: 'center'
        });
        doc.autoTable(content);
        if (isEmail) {
            var blob = doc.output('blob');
            var data = new FormData();
            data.append("userId", params);
            data.append("emailId", empEmailId);
            data.append("file", blob);
            data.append("type", "PDF");
            var apiRequest = apiUtility.apiInputRequest;
            apiRequest.endPoint = BASE_URL_EMAIL_TO_EMPLOYER;
            apiRequest.data = data;
            await apiUtility.sendAsyncAPIDataPOST(apiRequest)
                .then(response => {
                    //console.log("Response 8: ",response);
                    //console.log("Email Response : ", response);
                    //setauditMsg('Email Sent successfully.');
                    //setauditalertshow(true);
                    setsuccessMsg(response.data);
                    setShowSuccess(true);
                    //setTimeout(() => closeAlert, 6000);
                    setShowSpinner(false);
                }).catch(error => {
                    setErrorMsg("Error while sending email");
                    setShowErrorMsg(true);
                    setShowSpinner(false);
                });
        } else {
            doc.save("LCPT_UserAuditReport-" + userList.user_id + ".pdf");
            setShowSpinner(false);
            setsuccessMsg('File downloaded successfully');
            setShowSuccess(true);
        }
    }


    const formatChange = e => {
        const { name, value } = e.target;
        (value == "pdf") ? setformat(1) : setformat(0);
        //setauditalertshow(false);
        setShowSuccess(false);

    }

    //Excel
    const exportAuditExcel = async (isEmail) => {
        var rowsCount = 6;
        const title = [{ A: 'Ontario Long Term Care Orientation Co-operative Webportal - User Audit Report' }];
        let userTable = (roleDropdownValue != 0 && homeDropdownValue != 0) ?
            [{ A: 'Name', B: 'User ID', C: 'Date Of Birth', D: 'Date', E: 'Home', F: 'Role', G: 'Course Industry' }]
            : [{ A: 'Name', B: 'User ID', C: 'Date Of Birth', D: 'Date' }];
        if (roleDropdownValue != 0 && homeDropdownValue != 0) {
            var courseSelected = "All";
            var roleName = "";
            var homeName = "";
            for (let element of homeTypes) {
                if (element.id === crsTypeDropdownValue) {
                    courseSelected = element.value;
                }
            };
            for (let element of homeList) {
                if (element.home_id === String(homeDropdownValue)) {
                    homeName = element.name;
                }
            };
            for (let element of roleList) {
                if (element.role_id === String(roleDropdownValue)) {
                    roleName = element.role_name;
                }
            };
            userTable.push({
                A: userList.firstName + " " + userList.lastName,
                B: userList.user_id,
                C: userList.dob,
                D: new Date().toLocaleString(),
                E: homeName,
                F: roleName,
                G: courseSelected
            });
        } else {
            userTable.push({
                A: userList.firstName + " " + userList.lastName,
                B: userList.user_id,
                C: userList.dob,
                D: new Date().toLocaleString()
            });
        }
        userTable.push("");
        userTable.push("");
        let courseVersionHeader = {
            A: 'Course Code', B: 'Course Title',
            // C: 'Version ID', D: 'Version No', E: 'Title', F: 'Description', G: 'Training Duration', H: 'Is Valid',
            C: 'Issued By', 
            //J: 'Badge URL', 
            D: 'Course Status', E: 'Expiry Date', F: 'Training URL'
        };
        userTable.push(courseVersionHeader);
        var apiRequest = apiUtility.apiInputRequest;
        apiRequest.endPoint = BASE_URL_GET_ALLCOURSE_VERSIONS_USER_BASED;
        apiRequest.data = {
            "courses": completeCourses,
            "userId": userList.user_id
        };
        await apiUtility.sendAPIDataPOST(apiRequest).then(response => {
            for (let item of response.data) {
                //apiRequest.endPoint = BASE_URL_GET_ALLCOURSE_VERSIONS + data.crsId + "/" + userList.user_id;
                //await apiUtility.fetchAsyncAPIDataGET(apiRequest).then(response => {
                    //console.log("Response 9: ",response);
                  //  response.data.forEach(function (item) {
                        rowsCount += 1;
                        var courseVersion = {};
                        courseVersion = {
                            A: item.crsId,
                            B: item.parentTitle,
                            // C: item.crsVersionId,
                            // D: item.versionNo,
                            // E: item.title,
                            // F: item.desc,
                            // G: item.trainDuration,
                            // H: item.valid,
                            C: item.orgIssued,
                            //J: item.badgeUrl,
                            D: item.equivalentCrsStatus,
                            E: item.validity,
                            F: item.training_url
                        };
                        userTable.push(courseVersion);
                   // });
                // }).catch(error=>{
                //     setErrorMsg("Error while processing.");
                //     setShowErrorMsg(true);
                // });
            }
        }).catch(error => {
            setErrorMsg("Error while processing.");
            setShowErrorMsg(true);
        });
        const finalData = [...title, ...userTable];
        const worksheet = XLSX.utils.json_to_sheet(finalData, {
            skipHeader: true,
        });
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, userList.user_id + "_AuditReport");
        const workbookblob = workbook2blob(workbook);
        var headerIndexes = [];
        finalData.forEach((data, index) =>
            data["A"] === "Course Code" ? headerIndexes.push(index) : null
        );
        const dataInfo = {
            titleCell: "A2",
            titleRange: "A1:F1",
            tbodyRange: `A7:E${finalData.length}`,
            uHeadRange: (roleDropdownValue != 0) ? `A2:G2` : `A2:D2`,
            uBodyRange: (roleDropdownValue != 0) ? `A3:G3` : `A3:D3`,
            theadRange:
                headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:F${headerIndexes[0] + 1}`
                    : null,
            theadRange1:
                headerIndexes?.length >= 2
                    ? `A${headerIndexes[1] + 1}:F${headerIndexes[1] + 1}`
                    : null,
            tFirstColumnRange:
                headerIndexes?.length >= 1
                    ? `A${headerIndexes[0] + 1}:A${rowsCount + headerIndexes[0] + 1}`
                    : null,
            tLastColumnRange:
                headerIndexes?.length >= 1
                    ? `D${headerIndexes[0] + 1}:E${rowsCount + headerIndexes[0] + 1}`
                    : null,

            tFirstColumnRange1:
                headerIndexes?.length >= 1
                    ? `A${headerIndexes[1] + 1}:A${rowsCount + headerIndexes[1] + 1}`
                    : null,
            tLastColumnRange1:
                headerIndexes?.length >= 1
                    ? `E${headerIndexes[0] + 1}:E${rowsCount + headerIndexes[1] + 1}`
                    : null,
        };


        XlsxPopulate.fromDataAsync(workbookblob).then((workbook) => {
            workbook.sheets().forEach((sheet) => {
                sheet.column("A").width(15);
                sheet.column("B").width(45);
                sheet.column("C").width(15);
                sheet.column("D").width(30);
                sheet.column("E").width(45);
                sheet.column("F").width(30);
                sheet.column("G").width(30);
                // sheet.column("I").width(20);
                // sheet.column("J").width(20);
                // sheet.column("K").width(20);
                // sheet.column("L").width(20);
                // sheet.column("M").width(30);


                sheet.range(dataInfo.titleRange).merged(true).style({
                    bold: true,
                    fontFamily: "Arial",
                    horizontalAlignment: "center",
                    verticalAlignment: "center",
                });
                sheet.range(dataInfo.uHeadRange).style({
                    fill: "A6A6A6",
                    bold: true,
                    horizontalAlignment: "center",
                });
                sheet.range(dataInfo.uBodyRange).style({
                    bold: true,
                    horizontalAlignment: "center",
                });
                if (dataInfo.tbodyRange) {
                    sheet.range(dataInfo.tbodyRange).style({
                        horizontalAlignment: "left",
                    });
                }
                sheet.range(dataInfo.theadRange).style({
                    fill: "A6A6A6",
                    bold: true,
                    horizontalAlignment: "center",
                });
                if (dataInfo.tFirstColumnRange) {
                    sheet.range(dataInfo.tFirstColumnRange).style({
                        bold: true,
                    });
                }
                if (dataInfo.tLastColumnRange) {
                    sheet.range(dataInfo.tLastColumnRange).style({
                        bold: true,
                        horizontalAlignment: "center",
                    });
                }
            });

            return workbook.outputAsync().then((workbookBlob) => {
                if (isEmail) {
                    var data = new FormData();
                    data.append("userId", params);
                    data.append("emailId", empEmailId);
                    data.append("file", workbookBlob);
                    data.append("type", "Excel");
                    var apiRequest = apiUtility.apiInputRequest;
                    apiRequest.endPoint = BASE_URL_EMAIL_TO_EMPLOYER;
                    apiRequest.data = data;
                    apiUtility.sendAPIDataPOST(apiRequest)
                        //axios.post(BASE_URL_EMAIL_TO_EMPLOYER, data,{headers})
                        .then(response => {
                            //console.log("Email Response : ", response);
                            setsuccessMsg(response.data);
                            setShowSuccess(true);
                        }).catch (error => {
                            setErrorMsg("Error while sending email");
                            setShowErrorMsg(true);
                        });
                    //setauditMsg('Email Sent successfully.');
                    //setauditalertshow(true);
                    

                    //setTimeout(() => closeAlert, 6000);
                    setShowSpinner(false);
                    return workbookBlob;
                } else {
                    //console.log("Ended with download");
                    const downloadAnchorNode = document.createElement("a");
                    downloadAnchorNode.setAttribute("href", URL.createObjectURL(workbookBlob));
                    downloadAnchorNode.setAttribute("download", "LCPT_UserAuditReport-" + userList.user_id + ".xlsx");
                    downloadAnchorNode.click();
                    downloadAnchorNode.remove();
                    setsuccessMsg('File downloaded successfully');
                    setShowSuccess(true);
                    //setTimeout(() => closeAlert, 6000);
                    setShowSpinner(false);
                }

            });
        });
        //return XLSX.writeFile(blob, "UserAuditReport_"+userList.user_id+"_LCPT.xlsx");//URL.createObjectURL(workbookBlob));
    }

    const workbook2blob = (workbook) => {
        const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
        };
        const wbout = XLSX.write(workbook, wopts);

        // The application/octet-stream MIME type is used for unknown binary files.
        // It preserves the file contents, but requires the receiver to determine file type,
        // for example, from the filename extension.
        const blob = new Blob([s2ab(wbout)], {
            type: "application/octet-stream",
        });
        return blob;
    };

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; i++) {
            view[i] = s.charCodeAt(i);
        }
        return buf;
    }

    



    return (
        <div>

            {/* // <Container className="w-100"> */}
            <Container fluid>
                <Row className="w-70" >
                    <Col xs={12} md={12} className='shadow p-3 mb-3 bg-white'>
                        <Row>
                            <Col xs={12} md={4}>
                                <FloatingLabel
                                    controlId="floatingHome"
                                    label="Home"
                                >
                                    <Form.Select aria-label="Floating label select example" value={homeDropdownValue} onChange={filterRoleDropdown}>
                                        <option value="0"> -- Please select home --  </option>
                                        {homeList?.map((data, id) => (
                                            (mapHomeList.some(item => item.home_id == data.home_id)) ? <option value={data.home_id} key={data.home_id}>{data.name} &nbsp; &#10004;</option> : <option value={data.home_id} key={data.home_id}>{data.name}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={4}>
                                <FloatingLabel
                                    controlId="floatingSelectGrid"
                                    label="Role"
                                >
                                    <Form.Select aria-label="Floating label select example" value={roleDropdownValue} onChange={setRoleValue}>
                                        <option value="0"> -- Please select role --  </option>
                                        {roleList?.map((data, id) => (
                                            (mapRoleList.some(item => item.role_id == data.role_id)) ? <option value={data.role_id} key={data.role_id}>{data.role_name} &nbsp; &#10004;</option> : <option value={data.role_id} key={data.role_id}>{data.role_name}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                            <Col xs={12} md={4}>
                                <FloatingLabel
                                    controlId="floatingSelectGrid"
                                    label="Course Applicable Industry"
                                >
                                    <Form.Select aria-label="Floating label select example" value={crsTypeDropdownValue} onChange={setCourseTypeValue}>
                                        <option value="0"> -- Please select applicable industry --  </option>
                                        {homeTypes?.map((data, id) => (
                                            <option value={data.id} key={data.id}>{data.value}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col xs={0} md={9}></Col>
                            <Col xs={12} md={3} style={{ marginTop: '0.5pc' }}>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <Button variant="primary" onClick={(e) => {
                                            fetchCourseDetails();
                                        }}><FaSlidersH /> &nbsp; Filter</Button>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <Button variant="primary" onClick={(e) => {
                                            resetDetails();
                                        }}><FaAlignJustify /> &nbsp; Reset</Button>
                                    </Col>
                                </Row>


                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <Container fluid>
                <Paper elevation={1} className='p-2 mb-1' square>
                    <Row>
                        <Col>
                            {(alertshow) ?
                                <Alert severity="error" className='text-center'>{waitMsg}</Alert>
                                : ""}
                            {(successAlertshow) ?
                                <Alert severity="success">Course details are available to your employer.</Alert>
                                : ""}
                           
                            <br></br>
                            <Snackbar
                                open={alertshow}
                                autoHideDuration={6000}
                                onClose={() => setalertShow(false)}
                                severity="error"
                                message="You got an error!"

                            />

                            <Snackbar
                                open={successAlertshow}
                                autoHideDuration={6000}
                                onClose={() => setsuccessAlertshow(false)}
                                message="Course details are available to your employer."

                            />
                        </Col>
                    </Row>
                    <Row>
                        <br></br>
                        <Col xs={12} md={12}>
                            <BootstrapTable columns={columns}
                                keyField="crsId"
                                data={completeCourses}
                                ref={tableRef}
                                pagination={paginationFactory(options)}
                                filter={filterFactory()}
                                expandRow={expandRow}
                                filterPosition="top"
                                striped
                                hover
                                condensed />
                        </Col>
                    </Row>
                </Paper>
                <br></br>
                <br></br>
                <Paper elevation={1} className='p-2 mb-3' square>
                    <Row>
                        <br></br>
                        <br></br>

                        {/* <h3 style={{ borderRadius: '1.5px', textAlign: 'center', color: '#0f6fc5', padding: '0.2em' }} className='text-center'><FaRegListAlt />  Download Training Report</h3> */}
                        <Typography align='center' className='mt-2' color={"primary"} variant='h5'><FaRegListAlt /> Training Report</Typography>

                        <br></br>
                        {/* <Col xs={12} md={12} className='p-4'> */}
                            <Row>
                            <Col xs={12} md={6} className='p-4'>
                            <Card variant="soft" >
                                    <CardContent>
                                <Col xs={12} md={12}>
                                <br></br>
                                    <Form.Group id="formGridCheckbox">
                                        <Form.Label className='text-muted'>Please select format for Audit Report</Form.Label>
                                        <Form.Select name="format" onChange={formatChange}>
                                            <option> -- Choose your format -- </option>
                                            <option value="excel" key="1">Excel</option>
                                            <option value="pdf" key="2"> PDF</option>
                                        </Form.Select>
                                    </Form.Group>
                                   <br></br>
                                </Col>
                                <Col xs={12} md={12}>
                                    <Form.Group as={Col} controlId="formGridName">
                                        <Form.Label className='text-muted'>Email address</Form.Label>
                                        <Form.Control type="input" name="email" onBlur={updateEmpEmailId} placeholder="abc@xyz.com" />
                                    </Form.Group>
                                   
                                </Col>
                                <Col xs={12} md={12}>
                                    <Row>
                                        <Col xs={6} md={6} style={{ marginTop: '1.5pc' }}>
                                            <Form.Group>
                                                <Form.Check type="checkbox" onChange={() => setAuditCheck(false)} label="Verify & Send email" />
                                            </Form.Group>
                                        </Col>
                                        <Col xs={6} md={6} style={{ marginTop: '1.5pc' }} className='text-center'>
                                            <div>
                                                <Button variant="primary" onClick={() => sendDataThroughEmail()} disabled={auditCheck} size="sm"><FaRegPaperPlane /> Send</Button>
                                                {'   '}
                                                <Button variant="primary" onClick={downloadData} size="sm"><FaDownload /> Download</Button>
                                            </div>

                                        </Col>
                                    </Row>
                                </Col>
                                </CardContent>
                                </Card>
                                
                                </Col>
                            
                            <Col xs={12} md={6} className='p-4'>
                                <PieChart
                                    series={[
                                        {
                                            data: pieData,
                                            innerRadius: 20,cornerRadius: 4, paddingAngle: 2, 
                                            highlightScope: { faded: 'global', highlighted: 'item' },
                                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                        },
                                    ]}
                                    height={200}
                                    /> 
                                    </Col>
                            </Row>

                        {/* </Col> */}


                    </Row>
                    <br></br>
                </Paper>
            </Container>
            <Modal show={showSpinner} centered backdrop="static" style={{ "width": "5pc", "top": "20%", "left": "50%" }} keyboard={false}>
                <Modal.Body className='text-center'>
                    <Spinner variant='primary' role="status" animation="border" />
                </Modal.Body>
            </Modal>
            <SuccessPopUp show={showSuccess} data={successMsg} onHide={() => {setShowSuccess(false)}}/>
            <ErrorPopUp show={showErrorMsg} data={errorMsg} onHide={() => {setShowErrorMsg(false)}}/>

        </div>

    )
}

export default UserCourses;



